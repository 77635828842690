@tailwind base;
@tailwind components;
@tailwind utilities;

section {
  overflow: hidden;
}
body {
  overflow-x: hidden;
}
/* Navbar */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.navbar {
  height: 100px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  position: fixed;
  z-index: 2;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 10px;
}

.container {
  z-index: 1;
  width: 100vw;
  padding-right: 20px;
  padding-left: 40px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  width: auto;
  height: 80px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.navLogo {
  width: 80px;
  height: auto;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: space-between;
}

.navbar.colorChange {
  background: rgba(2, 191, 178, 0.9);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(7.6px);
}

.nav-item {
  font-size: 15px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0.260869562625885px;
  text-align: right;
  color: #ffffff;
  border-bottom: 2px solid transparent;
  margin-left: 10px;
}

.nav-items {
  font-size: 30px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0.260869562625885px;
  text-align: right;
  color: #ffffff;
  border-bottom: 2px solid transparent;
  margin-left: 10px;
}

.nav-links:hover {
  color: #19191a;
  transform: scale(1.2);
  transition: all 0.3s ease;
}
.nav-item .dot {
  color: #151515;
}
.nav-item:hover {
  color: #19191a;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.activated {
  color: #19191a;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 100%;
    opacity: 1;
    transition: all 0.2s ease;
  }

  .navbar {
    height: 80px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    top: 0;
    position: fixed;
    z-index: 2;
  }

  .nav-menu.active {
    background: #151515;
    position: absolute;
    float: right;
    opacity: 1;
    transition: all 0.3s ease;
    z-index: 2;
    padding: 10px 20px 10px 10px;
    max-width: 35%;
    left: 60%;
    right: 10px;
    border-radius: 10px;
  }

  .triangle {
    transition: all 0.3s ease;
    position: absolute;
    top: -17px;
    right: 14px;
    color: #151515;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.260869562625885px;
    text-align: left;
    padding: 10px;
    max-width: 100%;
    max-height: 100%;
  }

  .nav-links:hover {
    color: #02bfb2;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
    color: #02bfb2;
  }

  .nav-item {
    width: 100%;
    font-size: 25px;
  }

  .activated {
    color: #02bfb2;
  }

  .navbar-logo {
    color: #fff;
    justify-self: start;
    position: absolute;
    width: 80px;
    height: 60px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  .navLogo {
    align-items: center;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: auto;
    justify-self: start;
    text-decoration: none;
    left: 0;
    position: absolute;
    transform: translate(35%, -40%);
    width: 65px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .flag {
    display: block;
    float: right;
    position: absolute;
    top: 7px;
    right: 0;
    transform: translate(-200%, 70%);
  }
}

.connect-btn {
  background: rgba(255, 255, 255, 0.2);
  mix-blend-mode: normal;
  border-radius: 12px;
}

.connect-btn span {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shorten-address {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Home */
.hero {
  background-image: url("Assets/Img/bg.png");
  background-position: center center;
}

@media screen and (max-width: 1399px) {
  .hero {
    background-image: url("Assets/Img/bg.png");
    background-position: right bottom;
  }
}

@media screen and (max-width: 800px) {
  .hero {
    background-image: url("Assets/Img/bg.png");
    background-position: bottom right;
  }
}

@media screen and (max-width: 420px) {
  .hero {
    background-image: url("Assets/Img/bg-sm.png");
  }
}

@media screen and (min-width: 1400px) {
  .hero {
    background-image: url("Assets/Img/bg.png");
    background-size: 100vw;
    background-position: center center;
  }
}

.sub-hero {
  float: left;
  align-content: space-between;
}

.hero-heading {
  font-size: 100px;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: -2.9000000953674316px;
}

@media screen and (max-width: 650px) {
  .hero-heading {
    font-family: Poppins;
    font-size: 70px;
    font-weight: 600;
    letter-spacing: -1.4115043878555298px;
    text-align: right;
  }
}

@media screen and (max-width: 480px) {
  .hero-heading {
    font-family: Poppins;
    font-size: 50px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -1.4115043878555298px;
    text-align: left;
  }
}

/* @media screen and (max-width: 1000px) {
  .hero-heading {
    font-family: Poppins;
    font-size: 80px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -1.4115043878555298px;
    text-align: left;
  }
} */

.hero-list {
  font-size: 43px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0.53474360704422px;
}

.hero-content {
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.44999998807907104px;
  text-align: left;
}

.red-dot {
  height: 11px;
  width: 11px;
  background-color: #ff0000;
  border-radius: 50%;
  border-color: #ffffff;
  border-width: 2px;
  display: inline-block;
  margin-right: 10px;
}

.green-dot {
  height: 11px;
  width: 11px;
  background-color: #57bf02;
  border-radius: 50%;
  border-color: #ffffff;
  border-width: 2px;
  display: inline-block;
  margin-right: 10px;
}

/* ABOUT */

/* section 1 */
.text-bold {
  font-size: 18px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0.44999998807907104px;
  text-align: left;
}

.text-thin {
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.44999998807907104px;
  text-align: left;
}

.text-heading {
  font-size: 38px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0.47256410121917725px;
  text-align: left;
}

.solution {
  background-color: #f7f7f7;
}

.solution-heading {
  color: #595959;
}

.solution-text {
  color: #595959;
}

.team-name {
  font-size: 23px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.574999988079071px;
  text-align: left;
}

.team-position {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.38749998807907104px;
  text-align: left;
}

.about-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.38749998807907104px;
  text-align: left;
  color: #323232;
}

.person-name {
  font-size: 23px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.574999988079071px;
  text-align: left;
  color: #000000;
}

.person-position {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.38749998807907104px;
  text-align: left;
}

.person-image {
  border-radius: 100px;
  height: 124px;
  width: 124px;
}

@media screen and (max-width: 600px) {
  .person-image {
    border-radius: 100px;
    height: auto;
    width: 124px;
  }
}

.hidden {
  display: none;
}
.partner-text {
  font-size: 38px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0.47256410121917725px;
  text-align: left;
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;
}

.image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.animate.pop {
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-duration: 0.5s;
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
/* background width screen */
.bg-responsive {
  width: "100vw" !important;
}
.con-res {
  max-width: "1400px" !important;
  margin: "0 auto";
}

/* about */
@media screen and (min-width: 1400px) {
  .hero-about {
    background-image: url("Assets/Img/about-bg.png");
    background-size: 100vw;
  }
}

/* Video */
.video-responsive {
  margin: auto;
}

/* .video-responsive iframe {
  left: 0;
  top: 0;
  height: 80%;
  width: 80%;
  position: absolute;
  margin: auto;
} */

/* footer */
.footer-icon {
  width: "30px";
  height: "30px";
  margin-top: "30px";
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.goog-logo-link {
  display: none !important;
}

.trans-section {
  margin: 100px;
}

/* Style for the provider container */
.provider-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
}

/* Style for the provider name */
.provider-name {
  display: flex;
  align-items: center;
}

.provider-name img {
  margin-right: 10px;
}

.provider-name h4 {
  margin: 0;
}

/* Style for the buttons */
.provider-buttons button {
  margin: 0 5px;
  padding: 10px 20px;
  background-color: #6e0289;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: space-between;
}

.provider-buttons button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* Style for the select dropdown */
.provider-select select {
  display: block;
  margin: auto;
  padding: 10px;
  border: none;
  outline: none;
  background-color: #02bfb2;
  border-radius: 6px;
}

.provider-select select option {
  padding: 10px;
  outline: none;
}
